let config;

if (process.env.NODE_ENV === "production") {
  config = {
    email1: "DFA_4601711_CS@mail.nice-incontact.com",
    email2: "•	DFA_4601711_serviceissues@mail.nice-incontact.com"
  };
} else {
  config = {
    email1: "customerservice@dfamilk.com",
    email2: "serviceissues_dallas@dfamilk.com"
  };
}

//console.log(config);
export { config }