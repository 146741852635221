<template>

<div class="crp-content">
    <div class="login-background row">
        <div class="col-1">
        </div>
        <div class="col-11">
            <img id="background-image-fullscreen" src="../assets/WebApp_Background_Farm_2.jpg" alt="DFA Dairy Brands">
        </div>
    </div>
    <div class="fixed-top">
    <span class="site-header">DFA Dairy Brands Order & Inventory Access</span>
    </div>
    <div class="crp-topnav">
        <a class="brandLogo" href="#">
            <img src="../assets/DFA-Logo-Vert.png" alt="DFA Dairy Brands">
        </a>
    </div>
    <div class="crp-login row justify-content-center initial-credentials-panel">
        <div class="login-form">
            <div class="row" style="display:flex; justify-content: space-evenly; flex-wrap: wrap;">
              <div class="col-2 crp-login-panel pageHeader"><p>{{ msg1 }}</p></div>
              <card id="card" class="col-4">
                <div id="cardImage">
                    <avatar type="image" size="large" shape="circle">
                        <vue-feather type="mail" class="feather-32"></vue-feather>
                    </avatar>
                </div>
                <div style="margin-bottom: 4px;text-align: center;">
                    <cardHeader style="background: transparent; border-style: none;">                                    
                            <div style="margin-bottom: 4px;text-align: center;">
                                <cardTitle id="cardHeader">
                                  Current Customer?
                                </cardTitle>
                            </div>
                    </cardHeader>
                </div>
                <cardSubtitle id="card-subtitle">
                  <p>If you need a new User ID, contact your Sales Representative / eCRP Administrator to let them know you are ready to begin the Online Order Process; or click on the button below to email us at </p>
                  <div v-if="env">
                    <p>{{configData.configProd.emailCurrentCustomer}}</p>
                  </div> 
                  <div v-else>
                    <p>{{configData.config.emailCurrentCustomer}}</p>
                  </div>
                  <b>Be sure to include:</b>
                  <ol style="text-align: left;">
                    <li>Name of the Local Dairy</li>
                    <li>Your name</li>
                    <li>Store Name</li>
                    <li>Service Address, including Zip Code</li>
                    <li>Phone number</li>
                    <li>E-mail address</li> 
                  </ol>
                </cardSubtitle>
                <div style="margin-bottom: 4px;text-align: center;">
                    <cardAction style="text-align: center;">                                    
                      <kbutton :theme-color="'primary'" @click="() => btnClick('currentCustomer')">Email</kbutton>
                    </cardAction>
                </div>
              </card>
              <div class="col-1"></div>
              <card id="card" class="col-4">
                <div id="cardImage">
                    <avatar type="image" size="large" shape="circle">
                        <vue-feather type="globe" class="feather-32"></vue-feather>
                    </avatar>
                </div>
                <div style="margin-bottom: 4px;text-align: center;">
                    <cardHeader style="background: transparent; border-style: none;">                                    
                            <div style="margin-bottom: 4px;text-align: center;">
                                <cardTitle id="cardHeader">
                                  New Customer?
                                </cardTitle>
                            </div>
                    </cardHeader>
                </div>
                <cardSubtitle id="card-subtitle">
                    <p>For information regarding new service to your location including our service regions, please click on the button below.</p>
                </cardSubtitle>
                <div style="margin-bottom: 4px;text-align: center;">
                    <cardAction style="text-align: center;">                                    
                      <kbutton :theme-color="'primary'" @click="() => btnClick('newCustomer')">DFA Website</kbutton>
                    </cardAction>
                </div>
              </card>
              <div class="col-1"></div>
            </div>
        </div>        
    </div>
</div>
</template>

<script>
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
} from "@progress/kendo-vue-layout";
import { Button } from '@progress/kendo-vue-buttons';
import VueFeather from 'vue-feather';
import configData  from "./../../public/custom-config.json";

const cardsData = [
  {
    headerTitle: "Current Customer?",
    headerSubtitle: "If you need a User ID & Password, contact your Sales Representative / eCRP Administrator to let them know you are ready to begin the Online Order Process; or click on the button below to email us.",
    comments: [],
    buttonText: "LDF-OnlineOrderSetup@dfamilk.com",
    icon: "mail",
    buttonId: "currentCustomer"
  },
  {
    headerTitle: "New Customer?",
    headerSubtitle: "For information regarding new service to your location including our service regions, please click on the button below.",
    buttonText: "https://www.dfamilk.com/contact-us",
    icon: "globe",
    buttonId: "newCustomer"
  }
];

export default {    
  name: 'ContactPage',
  props: {
    msg1: String
  },
    components: {
        'card': Card,
        'cardHeader': CardHeader,
        'cardTitle': CardTitle,
        'cardSubtitle': CardSubtitle,
        'kbutton': Button,
        'vue-feather': VueFeather
    },
    data: function(){
        return {
            cards: cardsData,
            configData
        }
    },
    methods: {
      btnClick: function(buttonText) {            
            console.log(buttonText);
            if(buttonText == "currentCustomer"){
              //window.open("mailto:LDF-OnlineOrderSetup@dfamilk.com?subject=Current Customer");
              if (process.env.NODE_ENV == 'production') {
              let mailtoText = "mailto:" + configData.configProd.emailCurrentCustomer + "?subject=Current Customer";
              window.open(mailtoText);
              console.log(mailtoText);
              } else {
                let mailtoText = "mailto:" + configData.config.emailCurrentCustomer + "?subject=Current Customer";
                window.open(mailtoText);
                console.log(mailtoText);
              }
            }
            if(buttonText == "newCustomer"){
              window.open("https://www.dfamilk.com/contact-us", "_blank");
            }
      }
    },
    computed: {
      env() {
        console.log (process.env.NODE_ENV);
        return process.env.NODE_ENV === 'production' ? true : false
      }
    }       
}; 
</script>

<style>
.pageHeader {
  font-size: 1.5rem;
  font-weight: 700 !important;
  line-height: 1.2;
}
#cardHeader {
  margin-bottom: 4px;
  text-align: center;
  font-family: 'ITC Avant Garde Medium',sans-serif;
  font-weight: 700;
  font-size: 1rem;
}
.k-card {
    padding: 1.875rem 1.875rem 2.5rem;
}
/*#cardImage {
margin-bottom: 4px;
margin-top:30px;
text-align: center;
}*/
#card-subtitle {
    height: 340px; 
    max-width: 100%; 
    font-size: .9rem; 
    text-align: left; 
    font-family: myriad-pro, sans-serif; 
    font-weight: 500; 
    line-height: 1.5rem;
    color: #424242;
}
#card {
/*width: 470px;*/
height: 540px; 
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, .1); 
margin-top:15px;
border-radius: 30px 8px 8px 8px;

}
.feather-32{
width: 32px;
height: 32px;
}
.field_item {    
    padding: 0 0.625rem;
}
.crp-content .crp-login .crp-login-panel {
  color: #002f6c !important;
  border-radius: 10px;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  text-align: left !important;
  padding-left: 50px;
}
.login-form {
  position: relative;
  width: 350px;
  text-align: center;
}
.crp-content {
  margin: 0 15px 10px 15px;
}
.initial-credentials-panel {
  margin-top: -40px;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.fixed-top {
  background-color: #002f6c;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  align-items: center;
}
.brandLogo {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: -15px;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.brandLogo img {
  max-width: 150px;
  max-height: 50px;
}
.site-header {  
  padding: 0.5rem 1rem;
}
.crp-topnav {
    margin-top: 40px;
    padding: 0.5rem 1rem;
}
.k-button-solid-primary {
  border-color: #00539b !important;
  color: white;
  background-color: #00539b !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
  width: 150px;
}
.vue-feather{
  color: #00539b !important;
}
.login-background {
  position: absolute;
  top: 103px;
  left: 20%;
  right: 0;
  bottom: 0;
  /*background-color: #002f6c;*/
  text-align: center;
}
.login-background .image-fullscreen {
  width: 100%;
  background-size: cover;
  height: 100%;
  background-position: center;
}
#background-image-fullscreen {
    /*height: 100%;
    width: 100%;*/
    background-size: cover;
    background-position: center;
}
</style>
