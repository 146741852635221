<template>
  <ContactPage msg1="Contact Us"/>
</template>

<script>
import ContactPage from './components/ContactPage.vue';
import '@progress/kendo-theme-default/dist/all.css';
import 'bootstrap'; //
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config.js';
import './style/bootstrap.css';
export default {
  name: 'App',
  created(){
      document.title = "Contact Us"
  },
  components: {
    ContactPage
  },
  methods:{
    openChat: function () {   
        window.open("https://home-c39.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=06c4cd08-a63d-4e5f-85c9-91e88db5b66b&bu=4601711", "_blank");    
    }
  },
  mounted() {
      console.log(process.env.NODE_ENV)
      let appState = process.env.NODE_ENV === 'development' ? 'Im in Development Mode' : 'Im in Production Mode';
      console.log(appState);
  }
}
console.log(config);
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;  
}
</style>
